/* global $, ENABLE_HOURS, moment, quickverleih, openPopup, closePopup, setDataTablesOptions, qvapp */

// Andy: check merge - this functions called on all pages - renama it to init and call it only when the respective popups are shown (only the relevant functions should be called)
$(document).ready(function () {

    // Andy: check merge - just moved into a init function - this functions calls itself, check if needed and move to a file contracts.js or so. it should be called explicitly when it has to init the table
    function initReservationContractTable() {
        var contractTable = $('#contractsTable');
        var table = contractTable.DataTable();

        // order current loans table after 'nummer'
        table.column('0').order('desc').draw();

        $('#contractsTable_filter input').off('keypress').on('keypress', function (e) {

            let key = e.which || e.keyCode;
            if (key === 13) {

                let init = true;
                let type = $('#contractsTable').attr('data-type');

                $('#ContractsTableWrapper').addClass('loading');

                $.ajax({
                    type: "GET",
                    url: "includes/vertraege/ajax/contract_search.php?" + "&contract_filter=" + this.value + "&contract_init=" + init + "&contract_type=" + type,
                    success: function (data) {

                        let table = $('#contractsTable').DataTable();

                        table.clear();

                        $(data).each(function () {

                            if ($(this).is('tr')) {

                                table.row.add($(this));
                            }
                        });

                        table.draw();

                        setTimeout(function () {

                            $('#ContractsTableWrapper').removeClass('loading');
                        }, 200);
                    }
                });
            }
        });
    }

    (function init() {
        initReservationContractTable();
    })();
});

function initAddCustomerPopup() {
    var dataTablesOptions = setDataTablesOptions($('#CustomersTableWrapper').find('.data-table-block'));
    $('#contractCustomerTable').DataTable(dataTablesOptions);

    $('#contractCustomerTable_filter').find('input').off('keypress').on('keypress', function (e) {
        let key = e.which || e.keyCode;
        if (key === 13) {

            $('#CustomersTableWrapper').addClass('loading');

            $.ajax({
                type: 'POST',
                data: {
                    articleTenantId: _articleTenantId,
                    customer_filter: this.value
                },
                url: "includes/vertraege/ajax/ContractCustomersTableRowsView.php",
                success: function (data) {

                    let table = $('#contractCustomerTable').DataTable();

                    table.clear();

                    $(data).each(function () {

                        if ($(this).is('tr')) {

                            table.row.add($(this));
                        }
                    });

                    table.draw();

                    setTimeout(function () {

                        $('#CustomersTableWrapper').removeClass('loading');
                    }, 200);
                }
            });

        }

    });

    /* CUSTOMER */
    /*
    * Adds a customer into contract from popup
    */
    $(document).off('click', '.add-customer').on('click', '.add-customer', function () {
        var customerId = $(this).attr('id').split('_');
        customerId = customerId[1];

        if(quickverleih.config_params.check_customer_fields_on_adding_to_reservation) {
            quickverleih.contract.edit_customer_if_not_all_fields_defined(customerId, _callback_selected_customer);
        } else {
            _callback_selected_customer(customerId);
            closePopup();
        }
    });
}

/*
* Opens a popup with customers table

    callback_selected_customer - if this is specified the selected customer id is passed to the callback (new logic)
    if not specified - the old app logic is executed
*/
let _callback_selected_customer = null
// Called when a new reservation is initiated
function openCustomerTable(articleTenantId = null, callback_selected_customer = null, articlesLocations = []) {
    if ($("#openCustomerTable").attr('disabled') == 'disabled') {
        return;
    }

    _articleTenantId = articleTenantId;
    _callback_selected_customer = callback_selected_customer;

    $.ajax({
        url: 'includes/vertraege/ajax/ajaxContract.php',
        type: 'POST',
        data: {
            articlesLocations: articlesLocations,
            articleTenantId: _articleTenantId,
            method: 'openCustomerTable'
        },
        success: function (data) {
            var html = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
				html+= qvapp.i18n.t("label_lenders") + ' Auswählen';
            html += '</div></div>';
            html += data;
            html += '<br><br>';

            openPopup(html, '800px');
            initAddCustomerPopup();
        }
    });

}

function initAddArticlePopup(start_date = null, start_time = null, end_date = null, end_time = null) {
    var dataTablesOptions = setDataTablesOptions($('#ArticlesTableWrapper').find('.data-table-block'));
    $('#contractArticleTable').DataTable(dataTablesOptions);

    $(document).off('click', '.add-article').on('click', '.add-article', function() {
		closePopup();
		
		var articleId = $(this).attr('id').split('_');
		articleId = articleId[1];
        var availableSubarticlesList = $(this).data('available-subarticles');

		if(_article_ids_already_in_contract !== null && _article_ids_already_in_contract.includes(articleId)) {
			return;
		}

		// get rental interval
		// var interval = quickverleih.storage.get_inteval_for_insert_article();

    //    addArticleInContract(articleId, interval.start_date, interval.start_time, interval.end_date, interval.end_time, availableSubarticlesList);

       addArticleInContract(articleId, start_date, start_time, end_date, end_time, availableSubarticlesList);
	});

    function addArticleInContract(articleId, start_date, start_time, end_date, end_time, availableSubarticlesStr) {
        if (_callback_selected_article !== null) {
            _callback_selected_article(articleId, `${start_date} ${start_time}`, `${end_date} ${end_time}`, availableSubarticlesStr);
            return;
        }
    
        console.log("_callback_selected_article not defined");
    }
}
/*
* Opens a popup with article table
*/
let _article_ids_already_in_contract = null;
let _customer = null;
let _articleTenantId = null;
let _callback_selected_article = null
function openArticleTable(customer, articleTenantId = null, article_ids_in_contract = null, callback_selected_article = null, selectedLocation = '0', 
    start_date = null, start_time = null, end_date = null, end_time = null) {

    _article_ids_already_in_contract = article_ids_in_contract;
    _customer = customer;
    _articleTenantId = articleTenantId;
    _callback_selected_article = callback_selected_article;

    
    if(start_date == null && end_date == null) {
        // get start - end dates
        var interval = getIntervalForAddArticlePopup();

        start_date = interval.start_date;
        start_time = interval.start_time;
        end_date = interval.end_date;
        end_time = interval.end_time;
    }
    
    $.ajax({
        url: 'includes/vertraege/ajax/ajaxContract.php',
        type: 'POST',
        data: {
            customer: _customer,
            articleTenantId: _articleTenantId,
            start_date: start_date,
            start_time: start_time,
            end_date: end_date,
            end_time: end_time,
            method: 'openArticleTable'
        },
        success: function (data) {
            var html = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
            html += 'Artikel Auswählen';
            html += '</div></div>';
            html += data;
            html += '<br><br>';

            // show header
            openPopup(html, '1200px');

            // start the categories select
            quickverleih.app.init_categories_select(_customer, _articleTenantId, start_date, start_time, end_date, end_time, ""/* filter */, []/* specFilter */, selectedLocation/* locationId */);

            initAddArticlePopup(start_date, start_time, end_date, end_time);
        }
    });
}

// grays out articles that are already in contract
function grayOutArticlesInContract() {
    if (_article_ids_already_in_contract !== null) {

        for (const article_id of _article_ids_already_in_contract) {

            $(`#contractArticleTable #addArticle_${article_id}.add-article`)
                .css({ 'opacity': '0.25', 'cursor': 'default' })
                .attr('title', 'Artikel bereits hinzugefügt');
        }
    }
}

/*
* Repopulate popup article table
* specFilter is a json object containing key and value, used to filter articles by a specification (spezi) field
*/
function popupArticleTableReload(customer, articleTenantId, interval = false, filter, specFilter = false, locationId = 0, category = 0) {

    setTimeout(function () {
        $('#ArticlesTableWrapper').removeClass('loading');
    }, 200);

    if (typeof filter === 'undefined' || filter === null) {
        filter = '';
    }

    var dataTableWrapper = $('#ArticlesTableWrapper').find('.data-table-block');

    $.ajax({
        url: 'includes/vertraege/ajax/ContractArticlesTableRowsView.php',
        type: 'POST',
        data: {
            customer: customer,
            articleTenantId: articleTenantId,
            start_date: interval.start_date,
            start_time: interval.start_time,
            end_date: interval.end_date,
            end_time: interval.end_time,
            filter: filter,
            spec_filter: specFilter,
            location_id: locationId,
            category_id: category
        },
        success: function (data) {

            var dataTablesOptions = setDataTablesOptions(dataTableWrapper);
            $('#contractArticleTable').DataTable().destroy();
            $('#contractArticleTable tbody').html(data);
            $('#contractArticleTable').DataTable(dataTablesOptions);
            $('#contractArticleTable_filter input').val();

            // grays out articles that are already in contract
            grayOutArticlesInContract();

            // filter on enter as well
            $('#contractArticleTable_filter').find('input').on('keypress', function (e) {
                let key = e.which || e.keyCode;
                if (key === 13) {
                    popupArticleTableReload(_customer, _articleTenantId, interval, this.value, specFilter, locationId, category);

                    $('#ArticlesTableWrapper').addClass('loading');

                }
            });
        }
    });
}

/*
* Gets start - end dates for inputs in Add Article Popup
*/
function getIntervalForAddArticlePopup() {
    let insert_articles_interval = quickverleih.storage.get_inteval_for_insert_article();

    const now = moment();

    let should_set_interval = true;

    // check if interval already set, if not set, they will be updated in the if(should_set_interval) { ... } below
    if(insert_articles_interval.start_date !== null &&
        insert_articles_interval.start_time !== null &&
        insert_articles_interval.end_date !== null &&
        insert_articles_interval.end_time !== null) {

        // check if the already saved interval is in the past, if so, set the intervat again
        let saved_start_date_time = moment(`${insert_articles_interval.start_date} ${insert_articles_interval.start_time}`, "DD.MM.YYYY HH:mm");
        should_set_interval = now.isAfter(saved_start_date_time);
    }
    
    if(should_set_interval) {
        const today = now.format("DD.MM.YYYY");

        let startTime = "00:00";
        let endTime = "00:00";

        if(ENABLE_HOURS) {
            let nextHour = now.add(1, "hour");
            startTime = nextHour.format("HH:00");

            nextHour = nextHour.add(1, "hour");
            endTime = nextHour.format("HH:00");
        }

        quickverleih.storage.set_inteval_for_insert_article(today, startTime, today, endTime);

        insert_articles_interval = {
            start_date:today ,
            start_time: startTime,
            end_date: today,
            end_time: endTime
        }
    }

    return insert_articles_interval;
}

$(function () {
    quickverleih.contract = (function () {
        // checks if the customer needs edit, if not all mandatory fields are defined
		var edit_customer_if_not_all_fields_defined = function(customerId, callback_after_edit) {

            $.ajax({
                url: 'includes/vertraege/ajax/ajaxContract.php',
                type: 'POST',
                dataType: 'json',
                data: {
                    customerId: customerId,
					method: 'editCustomerIfNotAllFieldsDefined'
                },
                success: function (data) {

                    // this is set to true on the server if the customer needs to be edited
					if(data.edit_customer) {
                        openPopup(data.html, '800px');
                        $('#save_customer').data('edit_from_contract', 1);

                        quickverleih.contract.callback_after_edit = callback_after_edit;
                    } else {
                        closePopup();

                        callback_after_edit(customerId);

                        quickverleih.contract.callback_after_edit = null;
                    }
                }
            });
        }

        return {
			edit_customer_if_not_all_fields_defined: edit_customer_if_not_all_fields_defined,
            callback_after_edit: null
        };
    })();
});

// export section
window.openCustomerTable = openCustomerTable;
window.openArticleTable = openArticleTable;
window.popupArticleTableReload = popupArticleTableReload;